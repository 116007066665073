import PropTypes from 'prop-types'
import React from 'react'
import RTGLink from '@shared/link'
// import styled from 'styled-components'
import { styled, useTheme, css } from '@mui/material'
// import { theme } from '@constants/styles'
import Layout from '@components/layout'
import { graphql, useStaticQuery } from 'gatsby'
import Helmet from 'react-helmet'
import logo from '../assets/images/RTG_Customer-Care-RTG-Logo short jpg.jpg'
import { useAnalytics } from '../lib/hooks'

const Breadcrumbs = styled('div')`
  margin-top: 1rem;
  margin-bottom: 1rem;
  a {
    text-decoration: underline;
  }
  .current {
    margin-left: 5px;
  }
`

const WidgetWrapper = styled('div')`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .widget {
    width: 48%;
    min-width: 258px;

    h4 {
      font-weight: 600;
    }
  }
`

const Container = styled('div')(
  ({ theme }) => css`
    // MOBILE STYLES
    background: white;
    padding: 20px;
    a {
      color: ${theme.palette.primary.main};
      text-decoration: underline;
      &:hover {
        color: ${theme.palette.secondary.main};
      }
    }
    svg {
      margin-right: 10px;
    }
    h1 {
      font-size: 24px;
      color: ${theme.palette.primary.main};
      font-weight: 600;
      text-transform: uppercase;
      text-align: left;
      padding: 0;
      margin-bottom: 24px;
    }
    h2 {
      font-size: 20px;
      color: ${theme.palette.secondary.dark};
      font-weight: 600;
      text-transform: uppercase;
      border-bottom: 3px solid ${theme.palette.primary.main};
      padding-bottom: 3px;
      margin-bottom: 24px;
    }
    h3 {
      font-size: 20px;
      color: ${theme.palette.secondary.dark};
      font-weight: 400;
      margin-bottom: 18px;
      display: flex;
      align-items: center;
    }
    p,
    dl,
    dd {
      font-size: 1rem;
      line-height: 20px;
      margin-bottom: 18px;
    }
    ul {
      list-style: initial;
      padding-left: 24px;
      margin-bottom: 24px;
    }
    .cell {
      margin-bottom: 24px;
      &:first-child {
        border-bottom: 1px solid ${theme.palette.primary.main};
      }
    }
    .time {
      font-weight: 300;
      line-height: 20px;
    }
    // TABLET STYLES
    ${theme.breakpoints.up('sm')} {
      padding: 50px;
      h1 {
        font-size: 36px;
      }
      h2 {
        font-size: 28px;
        border-bottom: 5px solid ${theme.palette.primary.main};
        padding-bottom: 5px;
      }
      h3 {
        font-size: 24px;
      }
    }
    // DESKTOP STYLES
    ${theme.breakpoints.up('md')} {
      padding: 50px;
      h1 {
        font-size: 40px;
      }
      h2 {
        font-size: 32px;
      }
      h3 {
        font-size: 28px;
      }
      .cell:first-child {
        border-bottom: none;
      }
    }
  `,
)

const CareLogo = styled('img')(
  ({ theme }) => css`
    width: min(50vw, 230px);
    padding: 0 0 10px 5px;
    ${theme.breakpoints.down('sm')} {
      width: min(50vw, 345px);
    }
    ${theme.breakpoints.up('md')} {
      width: min(50vw, 380px);
    }
  `,
)

const Row = props => {
  const { children } = props
  return (
    <div className="grid-container">
      <div className="grid-x grid-margin-x">
        <div className="cell small-12">{children}</div>
      </div>
    </div>
  )
}

Row.propTypes = {
  children: PropTypes.any,
}

const StaticBreadcrumbs = () => (
  <Breadcrumbs className="breadcrumb grid-x grid-margin-x" role="navigation" aria-label="Breadcrumb">
    <ul className="breadcrumbs-list cell small-12 grid-x">
      <li>
        <RTGLink
          data={{
            slug: '/',
            category: 'customer service',
            action: 'home click',
            label: 'Home',
          }}
        >
          Home
        </RTGLink>
      </li>
      <li className="current">{'>'} Customer Service</li>
    </ul>
  </Breadcrumbs>
)

const ContactInformation = ({ data }) => (
  <section className="grid-x grid-margin-x">
    <div className="cell small-12 large-6">
      <h3>
        {data?.Icon1 && <img src={data?.Icon1.url} width="30px" height="30px" style={{ marginRight: '10px' }} alt="" />}
        {data?.SubsectionTitle1}
      </h3>
      {/* MARKDOWN DESCRIPTION 1 */}
      <div
        dangerouslySetInnerHTML={{
          __html: data?.Description1HTML?.data?.Description1HTML,
        }}
      />
    </div>
    <div className="cell small-12 large-6">
      <h3>
        {data?.Icon2 && <img src={data?.Icon2.url} width="30px" height="30px" style={{ marginRight: '10px' }} alt="" />}
        {data?.SubsectionTitle2}
      </h3>
      {/* MARKDOWN DESCRIPTION 2 */}
      <div
        dangerouslySetInnerHTML={{
          __html: data?.Description2HTML?.data?.Description2HTML,
        }}
      />

      {/* Track Delivery & Track Service */}
      <WidgetWrapper>
        {data?.TrackDelivery && (
          <div className="widget">
            <h4>Track Delivery:</h4>
            <iframe
              title="Delivery Widget"
              src="https://rtg.dispatchtrack.com/track/widget/f6cd6d3"
              height="97px"
              width="252px"
              frameBorder="0"
            />
          </div>
        )}
        {data?.TrackService && (
          <div className="widget">
            <h4>Track Service:</h4>
            <iframe
              title="Service Widget"
              src="https://rtgservice.dispatchtrack.com/track/widget/95881fe"
              height="97px"
              width="252px"
              frameBorder="0"
            />
          </div>
        )}
      </WidgetWrapper>
    </div>
  </section>
)

ContactInformation.propTypes = {
  data: PropTypes.object,
}

const LinkList = ({ data }) => (
  <section className="grid-x grid-margin-x">
    <div className="cell small-12 large-6">
      <h3>{data?.SubsectionTitle3}</h3>
      {/* SubSection3 Markdown */}
      <div
        dangerouslySetInnerHTML={{
          __html: data?.Description3HTML?.data?.Description3HTML,
        }}
      />
    </div>
    <div className="cell small-12 large-6">
      <h3>{data?.SubsectionTitle4}</h3>
      {/* Subsection4 Markdown */}
      <div
        dangerouslySetInnerHTML={{
          __html: data?.Description4HTML?.data?.Description4HTML,
        }}
      />
    </div>
  </section>
)

LinkList.propTypes = {
  data: PropTypes.object,
}

const useCustomerServiceQuery = () => {
  const data = useStaticQuery(graphql`
    query CustomerService {
      strapiCustomerService {
        id
        Description1HTML {
          data {
            Description1HTML
          }
        }
        Description2HTML {
          data {
            Description2HTML
          }
        }
        Description3HTML {
          data {
            Description3HTML
          }
        }
        Description4HTML {
          data {
            Description4HTML
          }
        }
        HeaderImage {
          id
          url
        }
        Icon1 {
          id
          url
        }
        Icon2 {
          id
          url
        }
        SectionTitle1
        SectionTitle2
        SubsectionTitle2
        SubsectionTitle1
        SubsectionTitle3
        SubsectionTitle4
        Title
        TrackDelivery
        TrackService
      }
    }
  `)
  return data || null
}

const CustomerService = ({ props }) => {
  const theme = useTheme()
  const { strapiCustomerService: StrapiData } = useCustomerServiceQuery()
  const data = StrapiData

  useAnalytics({
    title: data?.Title || 'Customer Service',
    type: 'customer-service',
    path: '/customer-service',
  })

  return (
    <Layout {...props}>
      <Helmet title={data?.Title || 'Customer Service'} />
      <Row>
        <StaticBreadcrumbs />
        <Container data={data}>
          <CareLogo src={data?.HeaderImage?.url || logo} alt="Rooms To Go Care Logo" />
          <h1>{data?.Title || 'Customer Service'}</h1>
          <h2>{data?.SectionTitle1 || 'Contact Us'}</h2>
          <ContactInformation data={data} />
          <h2>{data?.SectionTitle2 || 'Additional Information'}</h2>
          <LinkList data={data} />
        </Container>
      </Row>
    </Layout>
  )
}

CustomerService.propTypes = {
  props: PropTypes.any,
  data: PropTypes.object,
}

export default CustomerService
