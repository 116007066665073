import { useEffect } from 'react'
import { setupAnalytics } from '@helpers/google-tag-manager'

/**
 * @typedef {{title: string, type:string, path?: string}} Args
 * @param {Args} data
 * @returns {void}
 */
// default export is not needed here since hooks folder is encapsulating this module logic
// eslint-disable-next-line import/prefer-default-export
export function useAnalytics(data) {
  useEffect(() => {
    setupAnalytics({
      pageData: {
        type: data.type,
        title: data.title,
        path: data.path,
      },
    })
    // we want to run this function once, only on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
