import React, { useRef, useEffect } from 'react'

// eslint-disable-next-line import/prefer-default-export
export const useDidUpdate = (callback, deps = []) => {
  const hasMount = useRef(false)

  useEffect(() => {
    if (hasMount.current) {
      callback()
    } else {
      hasMount.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps])
}
